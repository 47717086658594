import classNames from 'classnames'
import { useAnalytics } from 'src/common/analytics'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'
import { useCallback, useEffect, useState } from 'react'
import { featureToggles } from 'src/common/constants/featureToggles'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { APP_BASE_PATH } from 'src/common/constants'
import Link from 'next/link'
import { ConsentButton } from './ConsentButton'
import { getCookies, setCookie } from 'src/common/helpers'

const CONTENTFUL_ENTRY_ID = '2ITbD2j9Uz8uG2nKpPoggv'
const CONTENTFUL_ENTRY_VERSION = '2'
const CONSENT_BANNER_COOKIE_KEY = 'tracking_consent_banner'
const CONSENT_BANNER_DISCLOSURE =
  'We use tracking technologies such as pixels and session replay tools to collect your information and interactions with our websites. By continuing to use our sites or closing this dialog, you acknowledge and agree to our use of tracking technologies. Please review our /privacy policy/ for more information.'

export const ConsentBanner = () => {
  const [showConsent, setShowConsent] = useState(false)
  const { getExperimentVariation } = useFeatureFlags()
  const consentBannerFlag = getExperimentVariation(
    experimentSectionByVariation[featureToggles.CONSENT_BANNER]
  )

  const { track } = useAnalytics()
  const { ref } = useIntersectionObserver({
    threshold: 0.5,
    onChange: () => {
      track(
        {
          additional_info: {
            disclosure_copy: CONSENT_BANNER_DISCLOSURE,
          },
          event_type: 'view',
          nav_link_section: 'TCB banner',
        },
        {},
        '_views/policy'
      )
    },
    freezeOnceVisible: true,
  })

  const generateCookie = useCallback(() => {
    const parsedUrl = new URL(APP_BASE_PATH().replace('www.', ''))

    const visitId = getCookies()?.['eh_visit_id']
    const payload = JSON.stringify({
      visitId,
      contentfulId: CONTENTFUL_ENTRY_ID,
      contentfulVersion: CONTENTFUL_ENTRY_VERSION,
    })

    setCookie(CONSENT_BANNER_COOKIE_KEY, payload, {
      path: '/',
      domain: parsedUrl.hostname,
      expiration: null,
    })
  }, [])

  const handleAgree = useCallback(() => {
    const data = {
      list_name: 'Tracking consent click event',
      nav_link_section: 'TCB banner',
      click_text: 'Ok',
      event_action: 'button_click',
    }

    generateCookie()
    track(data, {}, 'button_click')
    setShowConsent(false)
  }, [generateCookie, track])

  const handleClose = useCallback(() => {
    const data = {
      list_name: 'Close consent click event',
      nav_link_section: 'TCB banner',
      event_action: 'button_click',
    }

    generateCookie()
    track(data, {}, 'button_click')
    setShowConsent(false)
  }, [generateCookie, track])

  useEffect(() => {
    const cookieValue = getCookies()?.[CONSENT_BANNER_COOKIE_KEY]

    if (!cookieValue) setShowConsent(true)
    else setShowConsent(false)
  }, [setShowConsent])

  const showConsentBanner = consentBannerFlag === 'test' && showConsent

  if (!showConsentBanner) return null

  return (
    <div
      ref={ref}
      className={classNames(
        'w-full bg-white px-5 py-3 shadow-[0_6px_40px_-4px_rgba(16,24,40,0.11),0_9px_12px_-3px_rgba(16,24,40,0.05)] lg:px-16 lg:py-6'
      )}
    >
      <div className="flex flex-col items-center gap-3 lg:flex-row  lg:justify-between">
        <div className="flex">
          <p className={'text-xs font-normal lg:text-sm'}>
            We use tracking technologies such as pixels and session replay tools
            to collect your information and interactions with our websites. By
            continuing to use our sites or closing this dialog, you acknowledge
            and agree to our use of tracking technologies. Please review our{' '}
            <Link href={'/freedom-debt-relief-california-privacy-disclosures/'}>
              privacy policy
            </Link>{' '}
            for more information.
          </p>
          <div className="ml-2">
            <ConsentButton onClick={handleClose} classnames={'h-8 w-8'} />
          </div>
        </div>
        <ConsentButton
          onClick={handleAgree}
          type={'info'}
          classnames={
            'rounded-lg bg-blue-600 px-12 py-2 text-sm font-bold text-white lg:py-3 lg:text-lg'
          }
        />
      </div>
    </div>
  )
}
